.project-research {
  background-color: rgb(229, 240, 241);
  margin-bottom: 70px;
}

.project-container,
.research-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.project-card,
.research-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #e2e2f7;
  transition: transform 0.3s ease-in-out;
}

.project-card:hover,
.research-card:hover {
  transform: scale(1.05);
}

@media (min-width: 600px) {
  .project-card,
  .research-card {
    width: calc(50% - 40px);
  }
}

@media (min-width: 900px) {
  .project-card,
  .research-card {
    width: calc(33.33% - 40px);
  }
}

.project-card h4,
.research-card h4 {
  margin-bottom: 10px;
  color: #333;
}

.project-card p,
.research-card p {
  margin-bottom: 15px;
  color: #555;
}

.project-card strong,
.research-card strong {
  font-weight: bold;
  color: #777;
}

.project-card Button,
.research-card Button {
  margin-top: 10px;
}

.Button {
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  padding: 10px 20px;
  margin-top: 10px;
  border: 2px solid #3498db;
  border-radius: 5px;
  color: #3498db;
  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.Button:hover {
  background: #3498db;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .project-research {
    margin-right: 25px;
    margin-left: 25px;
  }
}
