.footer {
  background-color: #f8f9fa;
  padding: 5px 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.app {
  position: relative;
  z-index: 2;
}
.icon-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-icon {
  margin: 0 10px;
}

.footer caption {
  font-size: 14px;
  color: #555;
}
