.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5f0f1;
  padding: 50px;
}

.bio {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.2em;
  color: #393f44;
  line-height: 1.5;
  font-weight: 300;
}

.description {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  width: "70%";
}

.bio h1 {
  font-size: 2.5em;
  color: #393f44;
  margin-bottom: 15px;
  line-height: 1.2;
}

.bio p {
  margin-bottom: 15px;
  margin-top: 0;
}

.bio strong {
  color: #70b2b9;
}

.bio i {
  font-style: italic;
  color: #70b2b9;
}

.skill-list {
  display: grid;
  color: #393f44;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
  justify-content: center;
  margin-bottom: 5px;
  padding: 15px 20px 10px 20px;
}

.skill-card {
  background-color: #e2e2f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  transition: transform 0.3s ease-in-out;
}

.skill-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.skill-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #393f44;
}

.skill-card ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skill-card ul li {
  margin: 5px;
  font-weight: 400;
}

/* Desktop view */
@media screen and (min-width: 768px) {
  .content-container {
    margin-right: 25px;
    margin-left: 25px;
  }
}

/* Mobile View */
@media (max-width: 600px) {
  .content-container {
    padding: 0px;
  }

  .skill-list {
    margin-bottom: 60px;
  }

  .description {
    width: 90%;
  }

  .bio {
    font-size: initial;
  }
}
