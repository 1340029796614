.experience-card {
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  overflow: scroll;
  background: #e2e2f7;
  margin: 20px;
  height: 230px;
}

.experience-image {
  border-radius: 30%;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.experience-details h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
}

.position {
  margin-top: 1;
  font-size: 1.5rem;
  color: #333;
}

.org-empType {
  font-weight: bold;
  color: #555;
}

.time-location {
  color: #777;
}

.description {
  color: #444;
  line-height: 1.5;
}

.files {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

@media (min-width: 600px) {
  .experience-card {
    max-width: 100%;
  }

  .experience-image {
    width: 80px;
    height: 80px;
  }
}

/* Mobile View */
@media (max-width: 600px) {
  .experience-details h3 {
    font-size: 1.2em;
  }

  .file-buttons {
    display: flex;
    flex-direction: column;
  }

  .experience-image {
    width: 80px;
    height: 80px;
  }

  .experience-details {
    flex: 1;
  }
}
