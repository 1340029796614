.contact-me-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20vh;
    max-width: fit-content;
  }
  
  .contact-form {
    width: 400px;
    padding: 20px;
    border: 2px solid #4f5bd5;
    box-shadow: 0 0 20px rgba(79, 91, 213, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  .contact-form > * {
    margin-bottom: 15px;
  }
  
  .contact-form button {
    background-color: #4f5bd5; 
    color: #fff; 
    transition: background-color 0.3s ease-in-out;
  }
  
  .contact-form button:hover {
    background-color: #3a48bd;
  }
  